import React, { useEffect } from 'react';


const LinodePage = () => {
    useEffect(() => {
        window.location.replace("https://marketing.vpsdozeroaoavancado.com.br/asset/7:afiliado-linode");
    }, []);

    return (
        <>
        </>
    );
}

export default LinodePage;